import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json"
import translationUZ from "./locales/uz/translation.json"
import translationRU from "./locales/ru/translation.json"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      uz: {
        translation: translationUZ,
      },
      ru: {
        translation: translationRU,
      },
    },
    fallbackLng: "en",
    supportedLngs: ["en", "uz", "ru"],
    interpolation: {
      escapeValue: false,
    },
  });
