import logo from "./assets/img/logo.svg";
import logo_black from "./assets/img/logo_black.svg";
import "./App.css";
import "./fonts.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import videosource from "./assets/video/haus-home-sequence.webm";
import ReactLenis, { useLenis } from "lenis/react";
import uzum from "./assets/img/uzum.png";
import fao from "./assets/img/fao.png";
import undp from "./assets/img/undp.png";
import ebrd from "./assets/img/ebrd.png";
import cgiar from "./assets/img/cgiar.png";
import kursiv from "./assets/img/kursiv.png";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const options = [
  { value: "en", label: "English" },
  { value: "uz", label: "O'zbek" },
  { value: "ru", label: "Русский" },
];

function App() {
  const heading = useRef();
  const container = useRef();

  const [selectedOption, setSelectedOption] = useState();

  const { i18n, t } = useTranslation();

  const onLanguageSwitch = (option) => {
    i18n.changeLanguage(option.value);
  };

  useEffect(() => {
    const lng = i18n.language || window.localStorage.i18nextLng;
    setSelectedOption(options.find((l) => l.value === lng));
  }, [i18n.language]);

  console.log(selectedOption);

  return (
    <ReactLenis root>
      <div className="app" ref={container}>
        <div className="navbar">
          <div>
            <img src={logo_black} className="logo" />
          </div>
          <nav>
            <a href="#about">About</a>
            <a href="#partners">Partnerships</a>
            <a href="#contacts">Contacts</a>

            {/* <ReactSelect
              value={selectedOption}
              options={options}
              onChange={onLanguageSwitch}
              className="lang-select-container"
              classNamePrefix="lang-select"
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  neutral0: "#ffffff",
                  neutral10: "#999999",
                  primary25: "#F190F9",
                  neutral5: "#2791F7",
                  neutral20: "#aaaaaa",
                  neutral80: "#000000",
                },
              })}
            /> */}
          </nav>
        </div>
        <header className="header">
          <div className="heading" ref={heading}>
            <div>
              Powering <br />
              <span className="purple">connections</span>
            </div>
            <div className="">
              Sparking <span className="blue">actions</span>
            </div>
          </div>
          <div className="bottom">
            <div>@hackonnect</div>
            <div className="self-center">
              event & program management tailored for tech companies
            </div>
            <div>
              <a href="https://www.linkedin.com/in/mutalov/" className="credit">
                website by Sarvar Mutalov
              </a>
            </div>
          </div>
        </header>
        <section id="about" data-color="white black">
          <div className="container main_description">
            <h2 className="text-center">
              Hackonnect creates dynamic events and programs designed to ignite
              actionable solutions within the tech community.
            </h2>
            <h3 className="text-center">
              We bring people, business and technology to spark new connections,
              new ideas and fuel growth.
            </h3>
          </div>
          <div className="container wide">
            <div className="horizontal-content">
              <div>WE EXIST TO CHALLENGE THE MONOTONOUS</div>
              <div>
                <video width="750" muted autoPlay loop>
                  <source src={videosource} type="video/mp4" />
                </video>
              </div>
              <div>WE PUSH LIMITS TO CREATE UNEXPECTED EXPERIENCES</div>
            </div>
          </div>
        </section>
        <section id="partners" data-color="white black">
          <div className="container">
            <h2 className="text-center main_description">
              We exist one year, but already worked with
            </h2>
          </div>
          <div className="container">
            <div className="companies">
              <img src={uzum} alt="uzum" />
              <img src={undp} alt="undp" />
              <img src={fao} alt="fao" />
              <img src={ebrd} alt="ebrd" />
              <img src={cgiar} alt="cgiar" />
              <img src={kursiv} alt="kursiv" />
            </div>
          </div>
        </section>
        <section id="contacts" data-color="black white" className="">
          <div className="container main_description">
            <h2 className="">Get in Touch</h2>
            <h3 className="">
              Have an event in mind? We’re here to help bring it to life.
            </h3>
            <span>
              <a href="mailto:hackonnectpeople@gmail.com" className="email">
                hackonnectpeople@gmail.com
              </a>
            </span>
            <h4>
              Your vision, our expertise—let’s create something extraordinary
              together.
            </h4>
          </div>
        </section>
        <footer>
          <div className="container wide">
            <div>
              <img src={logo_black} className="logo" />
            </div>
            <div>© 2024 Hackonnect</div>
            <div>All rights reserved.</div>
            <div className="skip"></div>
            <div>
              <a href="https://www.linkedin.com/in/mutalov/" className="credit">
                website by Sarvar Mutalov
              </a>
            </div>
          </div>
        </footer>
      </div>
    </ReactLenis>
  );
}

export default App;
